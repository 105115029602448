import React from 'react';
import '../css/common.css'
import '../css/aboutus.css'
import aboutusma from '../asset/aboutusma.png'
import { LazyLoadImage } from 'react-lazy-load-image-component';

function AboutUsMA() {
  return (
    <>
<div className='aboutbannerMobile'>
    <div style={{color:"white", margin:"auto", fontSize:"2.2rem", fontWeight:"bold", zIndex:"2"}} >ABOUT US</div>
</div>
<div className='aboutus'>

    <div className='aboutusCon'>
  <div className='aboutus-title'>
    <h1><strong>COMPANY<br/>INTRODUCTION</strong></h1>
  </div>

  <div className='abouttMobile'>
    <LazyLoadImage className='abou' src={aboutusma} effect="blur"/>
  </div>

  <div className='aboutus-content'>
    <div className='textSpacing'>
    <p>
      Welcome to BIGPIE C&T, your leading export partner for original Korean cosmetics.<br/>With our extensive experience in the beauty industry, we offer a variety of innovative products that incorporate the best Korean beauty traditions with modern advancements.<br/>
      <br/>
      With BIGPIE C&T, quality and affordability are at the forefront of our mission, ensuring all customers have access to the best quality Korean cosmetics.<br/>
      BIGPIE C&T has professionals in the area of ​​R&D Center, Overseas Sales, SCM, Design and Logistic who are dedicated to meeting the specific needs of their customers, whether launching a new brand or expanding their product line.<br/>
      <br/>
      Personalization is our specialty.<br/>
      We assist in developing your unique brand identity, from logo design to packaging, ensuring your products stand out in the competitive market.<br/>
      Our end-to-end service ensures a seamless journey from concept to consumer.<br/>
      <br/>
      Understanding the fast-paced nature of the cosmetics industry, we are committed to staying ahead of trends and technological advancements, providing products that exceed expectations.<br/>
      Trust and excellence are the pillars of our operations, aiming to build lasting partnerships based on mutual success.
    </p>

    </div>
  </div>

    </div>


</div>
   </>
  );
}

export default AboutUsMA;