import React from 'react';
import '../css/common.css'
import '../css/aboutus.css'
import j1 from '../asset/j1.png';
import j2 from '../asset/j2.png';
import j3 from '../asset/j3.png';
import j4 from '../asset/j4.png';
import j5 from '../asset/j5.png';
import j6 from '../asset/j6.png';
import aboutusNewPC1 from '../asset/newPic/aboutusNewPC1.jpg'
import { LazyLoadImage } from 'react-lazy-load-image-component';

function AboutCompany() {
  return (
    <>

<div className='aboutbanner'>
    <div style={{color:"white", margin:"auto", fontSize:"2rem", fontWeight:"bold", zIndex:"2"}} >ABOUT US</div>
</div>
<div className='aboutus'>

  <div className='aboutt'>
    <LazyLoadImage className='abou' src={aboutusNewPC1} effect="blur"/>
  </div>
  <div className='aboutt'>
    <LazyLoadImage className='abou' src={j2} effect="blur"/>
  </div>
  <div className='aboutt'>
    <LazyLoadImage className='abou' src={j3} effect="blur"/>
  </div>
  <div className='aboutt'>
    <LazyLoadImage className='abou' src={j4} effect="blur"/>
  </div>
  <div className='aboutt'>
    <LazyLoadImage className='abou' src={j5} effect="blur"/>
  </div>
  <div className='aboutt'>
    <LazyLoadImage className='abou' src={j6} effect="blur"/>
  </div>

</div>
    
   </>
  );
}

export default AboutCompany;