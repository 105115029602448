import React from 'react';
import '../../css/intro.css';
import I1 from '../../asset/01.png';
import I2 from '../../asset/02.png';
import I3 from '../../asset/03.png';
import I4 from '../../asset/04.png';
import I5 from '../../asset/05.png';
import I6 from '../../asset/06.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';

function Intro() {
    return(
        <section>
            <div className='main1'>
                <div className='intro'>
                    <div className='title-container'>
                        <h1><strong>ONE-STOP Solution On DARLI</strong></h1>
                    </div>
                    <div className='block-container'>
                        <div className='block'>
                            <LazyLoadImage src={I1} effect="blur"/>
                            <h2>BRANDING</h2>
                            <p>We offer full support in terms of brand development, also with respect to product labels. We can help you create a catchy brand name to go with a matching logo and your brand’s overall visual style</p>
                        </div>
                        <div className='block'>
                            <LazyLoadImage src={I2} effect="blur"/>
                            <h2>REGULATORY REVIEW</h2>
                            <p>We check the customs regulations of imported goods for your country before making an order</p>
                        </div>
                        <div className='block'>
                            <LazyLoadImage src={I3} effect="blur"/>
                            <h2>PACKAGING DEVELOPMENT</h2>
                            <p>We assist our clients by offering comprehensive packaging development services, ensuring their products stand out in the market</p>
                        </div>
                        <div className='block'>
                            <LazyLoadImage src={I4} effect="blur"/>
                            <h2>DETAILED PAGE DESIGN</h2>
                            <p>We offer a professionally crafted detailed page for your product. This ensures your product is presented with compelling and informative content to attract and engage customers</p>
                        </div>
                        <div className='block'>
                            <LazyLoadImage src={I5} effect="blur"/>
                            <h2>PRODUCT DESIGN</h2>
                            <p>We take pride in meticulously handling every aspect of product design, ensuring a seamless and efficient process. Our dedication to detail guarantees a final product that meets your highest standards and market expectations</p>
                        </div>
                        <div className='block'>
                            <LazyLoadImage src={I6} effect="blur"/>
                            <h2>LOGISTICS</h2>
                            <p>We have made contract with FedEx, DHL, EMS. Our Shipping charge will be reasonable. 
                            We have shipped to over 200 countries so far, and still expanding</p>
                        </div>
                    </div>
                </div>

                <div className='introMobile'>
                    <div className='title-container'>
                        <h1><strong>ONE-STOP Solution<br/>On DARLI</strong></h1>
                    </div>
                    <div className='block-container'>
                        <div className='blockMobile'>
                            <div>
                            <LazyLoadImage src={I1} effect="blur"/>
                            </div>
                            <div>
                            <h2>BRANDING</h2>
                            </div>
                        </div>
                        <div className='blockMobile'>
                            <div>
                            <LazyLoadImage src={I2} effect="blur"/>
                            </div>
                            <div>
                            <h2>REGULATORY REVIEW</h2>
                            </div>
                        </div>
                        <div className='blockMobile'>
                            <div>
                            <LazyLoadImage src={I3} effect="blur"/>
                            </div>
                            <div>
                            <h2>PACKAGING DEVELOPMENT</h2>
                            </div>
                        </div>
                        <div className='blockMobile'>
                            <div>
                            <LazyLoadImage src={I4} effect="blur"/>
                            </div>
                            <div>
                            <h2>DETAILED PAGE DESIGN</h2>
                            </div>
                        </div>
                        <div className='blockMobile'>
                            <div>
                            <LazyLoadImage src={I5} effect="blur"/>
                            </div>
                            <div>
                            <h2>PRODUCT DESIGN</h2>
                            </div>
                        </div>
                        <div className='blockMobile'>
                            <div>
                            <LazyLoadImage src={I6} effect="blur"/>
                            </div>
                            <div>
                            <h2>LOGISTICS</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Intro;
