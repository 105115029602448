import React, { useState } from 'react';
import '../css/common.css';
import '../css/aboutus.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import productsData from './ProductsData';

function OurProduct() {
  const [activeTab, setActiveTab] = useState(0);
  const tabs = ["purynn", "ondo"];

  return (
    <>
      <div className='ourproductbanner'>
        <div style={{color:"white", margin:"auto", fontSize:"2rem", fontWeight:"bold", zIndex:"2"}}>OUR PRODUCT</div>
      </div>

      <div className='ourproductbannerMobile'>
        <div style={{color:"white", margin:"auto", fontSize:"2.2rem", fontWeight:"bold", zIndex:"2", textAlign:"center"}}>OUR PRODUCT</div>
      </div>

      <div className="flex flex-col items-center p-4">
        <div className="flex space-x-4 mb-4">
          {tabs.map((tab, index) => (
            <button 
              key={index}
              className={`px-4 py-2 rounded-lg ${activeTab === index ? (tabs[index] === "purynn" ? "purynnColor text-white" : "bg-gray-500 text-white") : "bg-gray-300"}`}
              style={{width:"9rem", fontWeight:"bold"}}
              onClick={() => setActiveTab(index)}
            >
              {tab}
            </button>
          ))}
        </div>
      </div>

      <div className={activeTab === 0 ? 'aboutus' : 'aboutuss'}>
        <div className='abouttMobileP'>
          {productsData[tabs[activeTab]].map((product, idx) => (
            <div className='ourprdMobile' key={idx}>
              <div className='productImg'>
                <LazyLoadImage src={product.img} effect="blur" />
              </div>
              <div className='productDes'>
                <div className={`prd-title ${activeTab === 0 ? 'pu borderP' : ''}`}>
                  {product.title}
                </div>
                <div className={`prd-cate ${activeTab === 0 ? 'pu pur' : ''}`}>
                  {product.category}
                </div>
                <div className={`prd-des ${activeTab === 0 ? 'pur' : ''}`}>
                  {product.description.map((line, i) => (
                    <div key={i} className={activeTab === 0 ? "desc-line" : ""}>{line}<br/></div>
                  ))}
                  <br/><br/>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default OurProduct;