import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'react-phone-input-2/lib/style.css';
import { db, storage } from '../firebase';
import { getAuth } from "firebase/auth";
import { doc, getDoc, collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import emailjs from '@emailjs/browser';
import { v4 as uuidv4 } from 'uuid';
import '../css/common.css';
import '../css/registerus.css';

function OemInquiry() {
  const navigate = useNavigate();

  const [targetCountry, setTargetCountry] = useState('');
  const [productType, setProductType] = useState('');
  const [volume, setVolume] = useState('');
  const [quantity, setQuantity] = useState('');
  const [features, setFeatures] = useState('');
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  function formatTimestamp(date) {
    return date.toISOString().replace('T', ' ').split('.')[0];
  }

  const handleFileChange = (e) => {
    setFile(e.target.files[0] || null);
  };

  const uploadFileToStorage = async (uid) => {
    if (!file) return null;

    const today = new Date().toISOString().split('T')[0];
    const fileName = `${uuidv4()}-${file.name}`;
    const storageRef = ref(storage, `users/${today}/${uid}/${fileName}`);

    try {
      await uploadBytes(storageRef, file);
      return await getDownloadURL(storageRef);
    } catch (error) {
      console.error("File Upload Error:", error);
      return null;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!targetCountry || !productType || !quantity) {
      alert("Please enter required data.");
      setLoading(false);
      return;
    }

    try {
      const auth = getAuth();
      const user = auth.currentUser;

      if (!user) {
        alert("User is not logged in.");
        return;
      }

      const userDocRef = doc(db, "users", user.uid);
      const userDocSnap = await getDoc(userDocRef);

      if (!userDocSnap.exists()) {
        alert("User data not found.");
        return;
      }

      const userData = userDocSnap.data();
      const timestamp = formatTimestamp(new Date());
      const fileUrl = await uploadFileToStorage(user.uid);

      await addDoc(collection(db, "inquiry"), {
        uid: user.uid,
        firstName: userData.firstName,
        lastName: userData.lastName,
        countryCode: userData.countryCode,
        localPhoneNumber: userData.localPhoneNumber,
        email: userData.email,
        targetCountry,
        productType,
        volume,
        quantity,
        features,
        referenceFileUrl: fileUrl || null,
        createdAt: serverTimestamp(),
        timestamp
      });

      const templateParams = {
        firstName: userData.firstName,
        lastName: userData.lastName,
        countryCode: userData.countryCode,
        localPhoneNumber: userData.localPhoneNumber,
        email: userData.email,
        targetCountry,
        productType,
        volume,
        quantity,
        features,
        referenceFileUrl: fileUrl || "No file attached",
        timestamp
      };

      await emailjs.send('service_e6fxwyo', 'template_9jbh0se', templateParams, '-PYXUln711WX82nnA');

      alert('Registration Successful.\nDarlibaba Manager will contact you ASAP.');
      navigate('/');
    } catch (error) {
      alert('Invalid Info');
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && (
        <div className="loading">
          <span></span>
          <span></span>
          <span></span>
        </div>
      )}

      <div className='inquirybanner'>
        <div style={{color:"white", margin:"auto", fontSize:"2rem", fontWeight:"bold", zIndex:"2"}}>Inquiry</div>
      </div>

      <div className='inquirybannerMobile'>
        <div style={{color:"white", margin:"auto", fontSize:"2.2rem", fontWeight:"bold", zIndex:"2", textAlign:"center"}}>INQUIRY</div>
      </div>

      <div style={{minHeight:"800px", display:"flex", backgroundColor:"#f4f4f4"}}>
        <div className="register-us-container">
          
          <div className="register-us-section">
            <h2 className="register-us-section-title">Product Info</h2>
            <form className="register-us-form">
              <div className="register-us-form-group">
                <label>Target Country<span style={{color:"red"}}>*</span></label>
                <input type="text" value={targetCountry} onChange={(e) => setTargetCountry(e.target.value)} placeholder="Enter target country" />
              </div>
              <div className="register-us-form-group">
                <label>Product Type<span style={{color:"red"}}>*</span></label>
                <input type="text" value={productType} onChange={(e) => setProductType(e.target.value)} placeholder="ex. skincare, serum.." />
              </div>
              <div className="register-us-form-group">
                <label>Volume</label>
                <input type="text" value={volume} onChange={(e) => setVolume(e.target.value)} placeholder="ex. g, ml" />
              </div>
              <div className="register-us-form-group">
                <label>Quantity<span style={{color:"red"}}>*</span></label>
                <input type="text" value={quantity} onChange={(e) => setQuantity(e.target.value)} placeholder="How many units do you need?" />
              </div>
              <div className="register-us-form-group">
                <label>Other Features</label>
                <input type="text" value={features} onChange={(e) => setFeatures(e.target.value)} placeholder="Describe other features" />
              </div>
              <div className="register-us-form-group">
                <label>Reference File</label>
                <div className="file-upload-container">
                <label htmlFor="file-upload" className="custom-file-upload">
                  📁 Choose File
                </label>
                <input id="file-upload" type="file" onChange={handleFileChange} />
                <span className="file-name">{file ? file.name : "No file selected"}</span>
              </div>
              </div>
            </form>
          </div>

          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <button className="register-us-submit-button" onClick={handleSubmit}>Submit</button>
          </div>
          
        </div>
      </div>
    </>
  );
}

export default OemInquiry;