const ProductsData = {
    purynn: [
      {
        img: require('../asset/purynnList/purynn2.jpg'),
        title: "PURYNN CERAPANO™ PEPTIDE HYDRATION TONER 100ml",
        category: "#StrengthensSkinBarrier #SoothingHydration #MoistureBoost",
        description: [
          "· Lightweight hydrating mist formula",
          "· Strengthens the skin barrier with Ceramide, Panthenol, and Amino acids",
          "· Hyaluronic acid and Madecassoside provide hydration and soothing effects",
          "· Peptides enhance skin elasticity and vitality",
          "· Salicylic acid exfoliates and cares for pores, maintaining clear skin"
        ]
      },
      {
        img: require('../asset/purynnList/purynn1.jpg'),
        title: "PURYNN CERAPANO™ TRI-LIPID NOURISH ESSENCE 100ml",
        category: "#StrengthensSkinBarrier #DeepHydration #Nourishment",
        description: [
          "· Silky, deeply moisturizing essence formula",
          "· Forms a strong skin barrier with a combination of Ceramide, Cholesterol, and Fatty acids",
          "· Amino acids and Panthenol help repair damaged skin and enhance hydration",
          "· Provides intense moisture and nourishment for soft, healthy skin"
        ]
      },
      {
        img: require('../asset/purynnList/purynn3.jpg'),
        title: "PURYNN CERAPANO™ RETINOL FIRMING AMPOULE 50ml",
        category: "#StrengthensSkinBarrier #Firming&AntiAging #MoistureSoothing",
        description: [
          "· Rich, high-concentration ampoule formula",
          "· Retinol and Peptides improve elasticity and reduce wrinkles",
          "· Hyaluronic acid and Madecassoside provide deep hydration and soothing care",
          "· Ceramide, Panthenol, and Amino acids reinforce the skin barrier",
          "· Antioxidants help protect skin from environmental stressors"
        ]
      },
      {
        img: require('../asset/purynnList/purynn4.jpg'),
        title: "PURYNN CERAPANO™ GLUTATHIONE MELA CREAM 50ml",
        category: "#StrengthensSkinBarrier #Brightening&AntiAging #MoistureSoothing",
        description: [
          "· Rich and deeply moisturizing cream formula",
          "· Glutathione and Niacinamide brighten skin tone",
          "· Ceramide, Panthenol, and Amino acids strengthen the skin barrier and lock in moisture",
          "· Hyaluronic acid and Madecassoside deliver deep hydration and calming care",
          "· Peptides help maintain skin elasticity and a healthy complexion"
        ]
      }
    ],
  
    ondo: [
      {
        img: require('../asset/ondoList/230921_ondo4136_.jpg'),
        title: "ONDO SKIN CLOTHO CREAM",
        category: "#Moisture #Nutrition #Skin Barrier Strengthening",
        description: [
          "Powerful moisturizing with Panthenol ingredient that cares the skin at the root! Skin barrier strengthening cream."
        ]
      },
      {
        img: require('../asset/ondoList/230921_ondo4053_.jpg'),
        title: "ONDO SKIN CLOTHO MIST",
        category: "#Luminosity #Moisture #Water Soluble Oil Mist",
        description: [
          "A luminous mist that moisturized dry skin with rich nutrition and adjusts the oil-water balance with a golden ratio of soothing essence + water soluble oil."
        ]
      },
      {
        img: require('../asset/ondoList/230921_ondo4083_.jpg'),
        title: "ONDO SKIN CLOTHO EYE CREAM",
        category: "#Wrinkles Improved #Improving Darkening #Fast Absorption",
        description: [
          "Volume eye cream that brightens dull corners of eyes and makes them firm across fine and deep wrinkles."
        ]
      },
      {
        img: require('../asset/ondoList/230921_ondo4117.jpg'),
        title: "ONDO SKIN CLOTHO CLEANSER",
        category: "#Subacidity #Skin Soothing #Whipped Cream Bubble",
        description: [
          "A cleanser that soothes sensitive skin with a pH-weak bubble that makes your skin soft and moist even after washing your face."
        ]
      },
      {
        img: require('../asset/ondoList/230921_ondo4067_.jpg'),
        title: "ONDO SKIN CLOTHO TONER",
        category: "#Soothing #Oil · water Balance #Fresh and Moist",
        description: [
          "A soothing toner that helps to control the oil and moisture balance of the skin th soothe sensitive skin."
        ]
      },
      {
        img: require('../asset/ondoList/230921_ondo4039_.jpg'),
        title: "ONDO SKIN CLOTHO AMPOULE",
        category: "#Luminosity #Whitening #Natural Glow",
        description: [
          "Highly concentrated triple (Whitening, elastic, glossy) intensive care product for bright and shiny skin."
        ]
      },
      {
        img: require('../asset/ondoList/230921_ondo3982.jpg'),
        title: "CHAOREUM AMPOULE",
        category: "#Whitening Wrinkle Improvement #Glossy Glow #Hypoallergenic",
        description: [
          "Elasticity and purity for sagging skin Intensive Ampoule for Skin Elasticity."
        ]
      },
      {
        img: require('../asset/ondoList/230921_ondo4024.jpg'),
        title: "CHAOREUM CREAM",
        category: "#Wrinkles Improved #Moisturizing #Hypoallergenic",
        description: [
          "Highly nourishing cream with a protective moisture barrier for lasting firmness."
        ]
      }
    ]
  };
  
  export default ProductsData;